import React, { useState } from "react"
import { Link, useStaticQuery } from "gatsby"
import addToMailchimp from 'gatsby-plugin-mailchimp'

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { StaticSection } from "../components/StaticSection"

import s1photo1 from '../images/section1-photo1.png'
import s1photo2 from '../images/section1-photo2.png'
import s1photo3 from '../images/section1-photo3.png'
import s1fg1 from '../images/section1-fg1.png'
import s1fg2 from '../images/section1-fg2.png'
import s1fg3 from '../images/section1-fg3.png'
import s1fg3Mobile from '../images/section1-fg3-mobile.png'

import pds1bg1 from '../images/pd-section1-bg1.png'
import pds1bg2 from '../images/pd-section1-bg2.png'

import border from '../images/borders/border-gray.svg';
import borderYellow from '../images/borders/border-yellow.svg';
import borderPurple from '../images/borders/border-purple.svg';
import borderGreen from '../images/borders/border-green.svg';
import borderRed from '../images/borders/border-red.svg';

import ben from '../images/user-photos/ben.png';
import beth from '../images/user-photos/beth.png';
import tana from '../images/user-photos/tana.png';
import sam from '../images/user-photos/sam.png';
import blair from '../images/user-photos/blair.png';
import chiara from '../images/user-photos/chiara.png';
import caleb from '../images/user-photos/caleb.png';
import ray from '../images/user-photos/ray.png';
import karen from '../images/user-photos/karen.png';
import marianne from '../images/user-photos/marianne.png';
import andrea from '../images/user-photos/andrea.png';
import alistair from '../images/user-photos/alistair.png';

import staticSectionImage1 from '../images/staticSectionImage1.png';
import staticSectionImage2 from '../images/staticSectionImage2.png';
import staticSectionImage3 from '../images/staticSectionImage3.png';
import staticSectionImage4 from '../images/staticSectionImage4.png';
import animationSectionImage from '../images/animationSectionImage.png';
import animationSlideImage1 from '../images/animationSlideImage1.png';
import animationSlideImage2 from '../images/animationSlideImage2.png';
import animationSlideImage3 from '../images/animationSlideImage3.png';

import photoFrameRed from '../images/photoFrameRed.png';
import photoFrameBlue from '../images/photoFrameBlue.png';
import photoFrameYellow from '../images/photoFrameYellow.png';
import photoFrameOrange from '../images/photoFrameOrange.png';

import partner1 from '../images/partners/partner1.png';
import partner2 from '../images/partners/partner2.png';
import partner3 from '../images/partners/partner3.png';
import partner4 from '../images/partners/partner4.png';
import partner5 from '../images/partners/partner5.png';
import partner6 from '../images/partners/partner6.png';
import partner7 from '../images/partners/partner7.png';
import partner8 from '../images/partners/partner8.png';
import partner9 from '../images/partners/partner9.png';
import partner10 from '../images/partners/partner10.png';
import partner11 from '../images/partners/partner11.png';
import partner12 from '../images/partners/partner12.png';
import partner13 from '../images/partners/partner13.png';
import partner15 from '../images/partners/partner15.png';

import feature1 from '../images/features/feature1.png';
import feature2 from '../images/features/feature2.png';
import feature3 from '../images/features/feature3.png';
import feature4 from '../images/features/feature4.png';
import feature5 from '../images/features/feature5.png';
import feature6 from '../images/features/feature6.png';
import feature7 from '../images/features/feature7.png';
import feature8 from '../images/features/feature8.png';
import feature9 from '../images/features/feature9.png';
import feature10 from '../images/features/feature10.png';
import feature11 from '../images/features/feature11.png';

import indicator from '../images/indicator.png';

const partners = [
  partner1, partner2, partner3, partner4, partner5, partner6, partner7, partner8, partner9, partner10,
  partner11, partner12, partner13, partner15
];

const features = [
  { image: feature1, label: 'Bank grade security' },
  { image: feature2, label: 'Granular permissioning' },
  { image: feature3, label: 'Digital Rights Management' },
  { image: feature4, label: 'Localized in 70 languages' },
  { image: feature5, label: 'Easy onboarding' },
  { image: feature6, label: 'Works on all platforms' },
  { image: feature7, label: 'Built-in Professional Development' },
  { image: feature8, label: 'COPPA compliant' },
  { image: feature9, label: 'Point of Sale' },
  { image: feature10, label: 'Quality Assurance' },
  { image: feature11, label: 'And lots more!' },
];

const IndexPage = (data) => {
  const page = data.pageContext.page || ''

  const staticData = useStaticQuery(graphql`
    query SitePageDataQuery {
      site {
        siteMetadata {
          pages {
            page
            color
            title 
            description
            staticSections {
              title
              description
              name
              role
              field
              userPhoto
              image
              imagePosition
              quoteColor
              photoFrame
              border
            }
            animationSection {
              title
              description
              name
              role
              field
              userPhoto
              image
              quoteColor
              photoFrame
            }
          }
        }
      }
    }
  `);

  const pageData = staticData.site.siteMetadata.pages.find(p => p.page === page);

  const getImage = (name) => {
    switch (name) {
      case 'beth':
        return beth;
      case 'tana':
        return tana;
      case 'sam':
        return sam;
      case 'blair':
        return blair;
      case 'chiara':
        return chiara;
      case 'caleb':
        return caleb;
      case 'ray':
        return ray;
      case 'karen':
        return karen;
      case 'marianne':
        return marianne;
      case 'andrea':
        return andrea;
      case 'alistair':
        return alistair;
      case 'borderGray':
        return border;
      case 'borderYellow':
        return borderYellow;
      case 'borderRed':
        return borderRed;
      case 'staticSectionImage1':
        return staticSectionImage1;
      case 'staticSectionImage2':
        return staticSectionImage2;
      case 'staticSectionImage3':
        return staticSectionImage3;
      case 'staticSectionImage4':
        return staticSectionImage4;
      case 'animationSectionImage':
        return animationSectionImage;
      case 'photoFrameRed':
        return photoFrameRed;
      case 'photoFrameBlue':
        return photoFrameBlue;
      case 'photoFrameYellow':
        return photoFrameYellow;
      case 'photoFrameOrange':
        return photoFrameOrange;
      default:
        return null;
    }
  }

  const [email, setEmail] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [formResult, setFormResult] = useState();
  const [selectedAnimationItem, setSelectedAnimationItem] = useState(1);

  const animationList = [
    { label: 'Instant slides', value: 1 },
    { label: 'Automatic worksheets', value: 2 },
    { label: 'Practice mode', value: 3 },
  ];

  return (
    pageData && <Layout color={pageData.color} page={page}>
      <SEO title="Home" />

      <section className="section1" style={{background: pageData.color}}>
        <div className="left-section">
          <h1>{pageData.title}</h1>
          {
            page !== 'pd' &&
            <>
              <p className="description">{pageData.description}</p>
              <a 
                className="btn-start" 
                style={{color: pageData.color}} 
                href="https://try.pencilapp.com/pencilapp-demo/" 
                target="_blank"
              >
                Book a demo <i className="material-icons-outlined">arrow_forward</i>
              </a>
            </>
          }
          {
            page === 'pd' &&
            <div className="desktop-profile">
              <p className="name">David Ramsey</p>
              <p className="role">Math PD Provider</p>
              <div className="reviews">
                <i className="material-icons-outlined">star</i>
                <i className="material-icons-outlined">star</i>
                <i className="material-icons-outlined">star</i>
                <i className="material-icons-outlined">star</i>
                <i className="material-icons-outlined">star</i>
                <span>(73 reviews)</span>
              </div>
            </div>
          }
        </div>
        {
          page === 'pd' &&
          <img className="ben" src={ben} />
        }
        <div className="right-section">
        {
          page !== 'pd' &&
          <>
            <img className="photo1" src={s1photo1} />
            <img className="photo2" src={s1photo2} />
            <img className="photo3" src={s1photo3} />
            <img className="fg1" src={s1fg1} />
            <img className="fg2" src={s1fg2} />
            <img className="fg3 desktop" src={s1fg3} />
            <img className="fg3 mobile" src={s1fg3Mobile} />
          </>
        }
        {
          page === 'pd' &&
          <div className="signup-form">
            <p>Join Pencil PD</p>
            <input type="text" placeholder="First name" name="FNAME" onChange={e => {
              setFormResult(null)
              setFirstName(e.target.value)
            }} />
            <input type="text" placeholder="Last name" name="LNAME" onChange={e => {
              setLastName(e.target.value)
              setFormResult(null)
            }} />
            <input type="email" placeholder="Email address" required name="EMAIL" className="required email" onChange={e => {
              setEmail(e.target.value)
              setFormResult(null)
            }} />
            {
              formResult && formResult.result === 'error' &&
              <div className="form-error" dangerouslySetInnerHTML={{__html: formResult.msg}}></div>
            }
            {
              formResult && formResult.result === 'success' &&
              <div className="form-success" dangerouslySetInnerHTML={{__html: formResult.msg}}></div>
            }
            <button type="button" className="button btn-signup" onClick={e => {
              setFormResult(null)
              addToMailchimp(
                email,
                {
                  FNAME: firstName,
                  LNAME: lastName,
                },
                'https://pencilapp.us17.list-manage.com/subscribe/post?u=5481933f13e69a48c041da0cf&amp;id=e8579f6485&SIGNUP=PencilappPD',
              ).then(res => {
                setFormResult(res)
                console.log(res)
              }).catch(err => {
                console.log(err)
              })
            }}>Sign up!</button>
            <p className="signin-text">
              <span>Have an account?</span>
              <a>Sign in</a>
            </p>
          </div>
        }
        {
          page === 'pd' &&
          <>
            <img className="pds1bg1" src={pds1bg1} />
            <img className="pds1bg2" src={pds1bg2} />
          </>
        }
        </div>
        {
          page === 'pd' &&
          <div className="mobile-profile">
            <div>
              <p className="name">David Ramsey</p>
              <p className="role">Math PD Provider</p>
              <div className="reviews">
                <i className="material-icons-outlined">star</i>
                <i className="material-icons-outlined">star</i>
                <i className="material-icons-outlined">star</i>
                <i className="material-icons-outlined">star</i>
                <i className="material-icons-outlined">star</i>
                <span>(73 reviews)</span>
              </div>
            </div>
            <img className="ben" src={ben} />
          </div>
        }
      </section>

      {
        pageData.staticSections.map(sectionData => (
          <StaticSection 
            key={sectionData.title} 
            sectionData={sectionData} 
            image={getImage(sectionData.image)} 
            userPhoto={getImage(sectionData.userPhoto)} 
            photoFrame={getImage(sectionData.photoFrame)} 
            border={getImage(sectionData.border)} 
          />
        ))
      }

      <section className="static-section animation-section">
        <div className={`animation-images-container ${selectedAnimationItem === 2 ? 'step2' : selectedAnimationItem === 3 ? 'step3' : ''}`}>
          <img className="section-image" src={getImage(pageData.animationSection.image)} />
          <img className="animation-image1" src={animationSlideImage1} />
          <img className="animation-image2" src={animationSlideImage2} />
          <img className="animation-image3" src={animationSlideImage3} />
          <div className="dots">
            <span className={`dot ${selectedAnimationItem === 1 ? 'selected' : ''}`}></span>
            <span className={`dot ${selectedAnimationItem === 2 ? 'selected' : ''}`}></span>
            <span className={`dot ${selectedAnimationItem === 3 ? 'selected' : ''}`}></span>
          </div>
        </div>
        <div className="section-content">
          <h2 className="feedback-title">{pageData.animationSection.title}</h2>
          <p className="feedback-description">
            <span className={`quote ${pageData.animationSection.quoteColor}`}>“</span>
            {pageData.animationSection.description}
          </p>
          {
            animationList.map(item => (
              <div 
                key={item.value}
                className={`item desktop ${selectedAnimationItem === item.value ? 'selected' : ''}`}
                onClick={() => setSelectedAnimationItem(item.value)}
              >
                <img className="item-indicator" src={indicator} />
                <span className="item-label">{item.label}</span>
              </div>
            ))
          }
          <div className="author">
            <img className="photo" src={getImage(pageData.animationSection.userPhoto)} />
            <img className="bg" src={getImage(pageData.animationSection.photoFrame)} />
            <div>
              <p>{pageData.animationSection.name}</p>
              <p>{pageData.animationSection.role}, {pageData.animationSection.field}</p>
            </div>
          </div>
        </div>
        <div className="mobile-item-container">
          {
            animationList.map(item => (
              <div 
                key={item.value}
                className={`item mobile ${selectedAnimationItem === item.value ? 'selected' : ''}`}
                onClick={() => setSelectedAnimationItem(item.value)}
              >
                <img className="item-indicator" src={indicator} />
                <span className="item-label">{item.label}</span>
              </div>
            ))
          }
        </div>
      </section>

      <section className="partners-section">
        <h2 className="feedback-title">An experienced team of technologists, educators and builders from...</h2>
        <div className="partners-container">
          {
            partners.map((p, i) => (
              <img key={`${i}_`} alt={`partner${i}`} src={p} />
            ))
          }
        </div>
      </section>

      <section className="features-section">
        <h2 className="feedback-title">Handles the business of education</h2>
        <div className="features-container">
          {
            features.map((f, i) => (
              <div key={`${i}_`} className="feature">
                <img alt={`feature${i}`} src={f.image} />
                <p>{f.label}</p>
              </div>
            ))
          }
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;
