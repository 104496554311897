import React from 'react';

export const StaticSection = ({ sectionData, image, userPhoto, photoFrame, border }) => {
  return (
    <>
      <section className="static-section">
        {
          sectionData.imagePosition === 'left' &&
          <img className="section-image" src={image} />
        }
        <div className="section-content">
          <h2 className="feedback-title">{sectionData.title}</h2>
          <p className="feedback-description">
            <span className={`quote ${sectionData.quoteColor}`}>“</span>
            {sectionData.description}
          </p>
          <div className="author">
            <img className="photo" src={userPhoto} />
            <img className="bg" src={photoFrame} />
            <div>
              <p>{sectionData.name}</p>
              <p>{sectionData.role}, {sectionData.field}</p>
            </div>
          </div>
        </div>
        {
          sectionData.imagePosition === 'right' &&
          <img className="section-image" src={image} />
        }
      </section>
      <img src={border} className="section-border" />
    </>
  )
}